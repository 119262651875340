import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const seoData = {
  title: 'Inicio',
  description: 'Albaricoque: Papelería y artículos varios',
  path: '/',
}

const IndexPage = () => (
  <Layout subtitle="¡Bienvenidos!">
    <SEO title="Home" pageData={ seoData }/>

    <h1 className="text-primary"> 
      Sitio en construcción :3
    </h1>

    <Link to="/social" className="text-tertiary">
      Información de redes sociales
    </Link>
  </Layout>
)

export default IndexPage
